.body-live .wc ul {
  list-style-type: disc;
  padding-left: 1.0rem;
  margin: 0;
}	

.body-live .wc ol {
  margin: 0;	
  padding-left: 1.0rem;
}

.body-live .wc li {
    display: list-item;	
    list-style-type: disc;
}

.body-live .wc ol li {
  list-style-type: decimal;
}

.body-live .wc {
  text-align: left;
}

.wc a {
  color: inherit;
  /* text-decoration: underline; */
}